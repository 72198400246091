.header-wapper {
    box-shadow: 0 3px 2px -2px #eee;
    margin-bottom: 8px;
    .header {
        padding: 20px 0;
        .logo {
            img {
                width: 212px;
            }
        }
        .search {
            padding-top: 5px;
        }
    }
    .top-menu {
        .nav-item{
            padding: 0 0.5rem;
        }
    }
}