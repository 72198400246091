.cart-wrapper {
    width: 100%;
    .cart-inner {
        position: relative;
        .cart {
            i {
                font-size: 20px;
                color: black;
            }
        }
        &:hover .cart i {
            color: #418deb;
        }
        .cart-basket {
            font-size: .7rem;
            position: absolute;
            top: -5px;
            right: -10px;
            width: 18px;
            height: 18px;
            color: #fff;
            background-color: red;
            border-radius: 50%;
        }
    }
}