.user-dropdown {
    .dropdown-menu {
        .dropdown-item, .dropdown-item-text {
            padding: 0.25rem 1rem;
        }
        .dropdown-item-text.user-balance {
            font-size: 13px;
        }
        &.dropdown-menu.show.dropdown-menu-right {
            width: 250px;
        }
    }
}
