.footer {
    background: #f2f2f2;
    padding: 20px 0 5px 0;
}
.navbar .nav-link  {
    color: #444 !important;
    font-weight: 500;
}
.navbar .nav-item {
    // padding-left: 1rem !important;
    // padding-right: 1rem !important;
}
.navbar .nav-link:hover {
    color: #2196f3 !important;
}